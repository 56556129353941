import axios from 'axios';
import axiosApiInstance from '../config/axios';

const baseURL = process.env.REACT_APP_ASSET_API;
const userUrl = 'api/v1';

export const getData = () => {
    return axiosApiInstance.get(`${userUrl}/data`)
};

export const searchArtist = (artistName: string) => {
    return axios.get(`${baseURL}/${userUrl}/music/artist/search/${artistName}`)
};
